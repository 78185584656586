<template>
  <div class="store">
    <router-view>
      <store-home></store-home>
    </router-view>
  </div>
</template>

<script>
import StoreHome from './StoreHome.vue'
export default {
  name: 'index',
  components: {
    StoreHome
  }
}
</script>

<style lang='scss' scoped>
@import '../../assets/styles/global';
.store{
  background: white;
  width: 100%;
  height: 100%;
}
</style>
